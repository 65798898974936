import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { createRequestOption } from '@shared/util/request.util';
import {
  DOCUMENT_PROPERTIES_QUERY_PARAMETER,
  IDocumentEntity,
} from '@shared/models/document.model';

interface IDocumentMenu {
  mainDocument: IDocumentEntity;
  childrenDocuments: IDocumentEntity[];
}

@Injectable({ providedIn: 'root' })
export class SearchDocumentService {
  // needs to change the name
  nuxeoUrl = `${environment.nuxeoURL}`;
  nuxeoApiUrl = `${environment.nuxeoApiUrl}`;
  backendApiNuxeoUrl = `${environment.backendApiNuxeoUrl}`;

  constructor(private http: HttpClient) {}

  getDocumentsByParentId(
    id: string,
    pagination = { pageSize: 40, page: 0 },
  ): Observable<IDocumentEntity[]> {
    const params = createRequestOption({
      ecm_parentId: id,
      properties: DOCUMENT_PROPERTIES_QUERY_PARAMETER,
      enrichers: 'breadcrumb,hasFolderishChild,children',
      pageSize: pagination?.pageSize,
      currentPageIndex: pagination?.page,
      offset: 0,
    });

    return this.http
      .get(`${this.nuxeoUrl}/search/pp/advanced_document_content/execute`, {
        params,
      })
      .pipe(map(v => v['entries']));
  }

  getDocumentById(id: string, params?: any): Observable<IDocumentEntity> {
    return this.http.get<IDocumentEntity>(`${this.nuxeoUrl}/id/${id}`, { params });
  }

  getDocumentByPath(
    path: string,
    pagination = { pageSize: 1, page: 120 },
  ): Observable<IDocumentMenu> {
    const params = createRequestOption({
      properties: DOCUMENT_PROPERTIES_QUERY_PARAMETER,
      enrichers: 'breadcrumb,hasFolderishChild,children',
      pageSize: pagination.pageSize,
      currentPageIndex: pagination.page,
      offset: 0,
    });

    return (
      path
        ? of(path)
        : this.http
            .get<{ value: string }>(`${this.nuxeoApiUrl}/tenant/doc-path`)
            .pipe(map(response => decodeURIComponent(response.value)))
    ).pipe(
      switchMap(resolvedPath =>
        this.http
          .get<IDocumentEntity>(`${this.nuxeoUrl}/path/${resolvedPath}`, { params })
          .pipe(
            switchMap((mainDocument: IDocumentEntity) =>
              this.getDocumentsByParentId(mainDocument.uid).pipe(
                map(childrenDocuments => ({
                  mainDocument,
                  childrenDocuments,
                })),
              ),
            ),
          ),
      ),
      catchError((err: HttpErrorResponse) => {
        console.error(err);
        return of();
      }),
    );
  }

  isExpandable(node: any): boolean {
    console.log(node);
    return node.type !== 'SectionRoot'; // if it is expandbale
  }
}
