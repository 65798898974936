/* eslint-disable no-use-before-define */
export interface IUserProfileDetails {
  avatar: string;
  birthdate: string;
  gender: boolean;
  locale: string;
  phonenumber: string;
}

export interface IUserProfile {
  userprofile: IUserProfileDetails;
}

export interface IExtendedGroups {
  name: string;
  label: string;
  url: string;
}

export interface IUserProperties {
  company: string;
  email: string;
  firstName: string;
  groups: string[];
  lastName: string;
  username: string;
  tenantId?: string;
  password?: string;
}

export interface IUser {
  'entity-type': string;
  id: string;
  isAdministrator: boolean;
  isAnonymous: boolean;
  properties: IUserProperties;
  extendedGroups?: IExtendedGroups[];
  contextParameters?: IUserProfile;
}

export class DisplayedUser {
  public fullName: string;
  constructor(
    public id: string,
    public avatar: string,
    public username: string,
    public firstName: string,
    public lastName: string,
    public gender: string | undefined,
    public company: string,
    public email: string,
    public groups: string[] = [],
    public extendedGroups?: IExtendedGroups[],
    public birthdate?: string,
    public phonenumber?: string,
  ) {
    this.fullName = `${this.firstName} ${this.lastName}`;
  }
}

export interface NuxeoProperties {
  id: string;
  properties: NuxeoUserProperties;
  extendedGroups: ExtendedGroup[];
  groups: string[];
  email: string;
  firstName: string;
  lastName: string;
  company: string | null;
  anonymous: boolean;
  administrator: boolean;
  'entity-type': string;
  isAdministrator: boolean;
  isAnonymous: boolean;
  username: string;
}

export interface NuxeoUserProperties {
  firstName: string;
  lastName: string;
  tenantId: string | null;
  groups: string[];
  company: string | null;
  email: string;
  username: string;
}

export interface ExtendedGroup {
  name: string;
  label: string;
  url: string;
}

export interface INewNuxeoProperties {
  id: string;
  properties: IUserProperties;
  extendedGroups: IExtendedGroups[];
  groups: string[];
  email: string;
  firstName: string;
  lastName: string;
  company: string | null;
  anonymous: boolean;
  administrator: boolean;
  'entity-type': string;
  isAdministrator: boolean;
  isAnonymous: boolean;
  username: string;
}

export interface INewFusionAuthProfile {
  id: string;
  username: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string;
  company: string | null;
  birthdate: string | null;
  tenantId: string;
  applicationIds: string[];
  nuxeoProperties: INewNuxeoProperties;
  base64ProfileImage: string;
}
