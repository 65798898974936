import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NuxeoAuthInterceptor } from './interceptor/nuxeo.interceptor';
import { AlertService } from '@shared/components/alert/services/alert.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth/componentss/services/auth.service';
import { AuthLoginGuard } from '../auth/componentss/services/auth-login-guard';
import { LoginService } from '../auth/componentss/services/login.service';
import { FusionAuthService } from '../auth/componentss/services/fusion-auth.service';
import { RoleService } from '../auth/componentss/services/roles/role.service';

export function initializeRoles(roleService: RoleService): () => void {
  return () => roleService.initializeToken();
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    CookieService,
    AuthLoginGuard,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: NuxeoAuthInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: RequestInterceptor,
    //   multi: true,
    //   deps: [Store],
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NuxeoAuthInterceptor,
      deps: [AlertService, AuthService, FusionAuthService, LoginService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeRoles,
      deps: [RoleService],
      multi: true,
    },
  ],
})
export class DmsCoreModule {}
