import { Injectable } from '@angular/core';
import { IAccountJwt } from './role.model';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private token: IAccountJwt | null = null;

  initializeToken() {
    this.setToken();
  }

  setToken(token?: IAccountJwt) {
    if (token) {
      this.token = token;
    } else {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        const decodedToken: IAccountJwt = jwtDecode(storedToken);
        this.token = decodedToken;
      } else {
        this.token = null;
      }
    }
  }

  getRoles(): string[] {
    return this.token?.roles || [];
  }

  hasRole(role: string): boolean {
    return this.getRoles().includes(role);
  }

  isAdmin(): boolean {
    return this.hasRole('admin');
  }
}
